<template>
  <Calendar :showIcon="true" icon="pi pi-calendar" dateFormat="dd/mm/yy" :placeholder="label" :disabledDates="disabledDates" :manualInput="false" class="calendar" />
</template>

<script>
import { usePrimeVue } from 'primevue/config'

export default {
  props: {
    label: {
      type: String,
      default: "Fecha",
    },
    disabledDates: {
      type: Array,
      default: []
    }
  },
  setup() {
    const primevue = usePrimeVue()
    primevue.config.locale.dayNamesMin = ["Do","Lu","Ma","Mi","Ju","Vi","Sa"]
    primevue.config.locale.monthNames = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
  }
}
</script>

<style scoped>
  .calendar {
    width: 350px;
    height: 50px;
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 2px solid var(--primary) !important;
    box-shadow: none !important;
    font-family: "klavika" !important;
    box-shadow: 0 10px 5px -5px rgba(35, 141, 130, 0.15) !important;
    margin-bottom: 0.5rem;
  }
  .calendar::placeholder {
    color: var(--primary) !important;
  }
</style>